// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Volumes/Data/Dropbox/Lior/Projects/Code/webserver/static/themasterlist/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-js": () => import("/Volumes/Data/Dropbox/Lior/Projects/Code/webserver/static/themasterlist/src/pages/add.js" /* webpackChunkName: "component---src-pages-add-js" */),
  "component---src-pages-edit-js": () => import("/Volumes/Data/Dropbox/Lior/Projects/Code/webserver/static/themasterlist/src/pages/edit.js" /* webpackChunkName: "component---src-pages-edit-js" */),
  "component---src-pages-index-js": () => import("/Volumes/Data/Dropbox/Lior/Projects/Code/webserver/static/themasterlist/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-share-js": () => import("/Volumes/Data/Dropbox/Lior/Projects/Code/webserver/static/themasterlist/src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Volumes/Data/Dropbox/Lior/Projects/Code/webserver/static/themasterlist/.cache/data.json")

