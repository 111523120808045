module.exports = [{
      plugin: require('/Volumes/Data/Dropbox/Lior/Projects/Code/webserver/static/themasterlist/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-76391501-13"},
    },{
      plugin: require('/Volumes/Data/Dropbox/Lior/Projects/Code/webserver/static/themasterlist/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser'),
      options: {"plugins":[],"pixelId":"334275557423062"},
    },{
      plugin: require('/Volumes/Data/Dropbox/Lior/Projects/Code/webserver/static/themasterlist/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
